import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import sanityClient from "../../utils/client"
const LinkObject = ({ mark, children }) => {
  const [clientProduct, setClientProduct] = useState(null)

  useEffect(() => {
    if (mark?._ref?.includes("shopifyProduct")) {
      sanityClient
        .fetch(`*[_id=="${mark._ref}" ][0]{"link":store.slug.current} `)
        .then(data => setClientProduct({ ...data, store: true }))
        .catch(console.error)
    } else {
      sanityClient
        .fetch(`*[_id=="${mark._ref}" ][0]{"link":slug.current} `)
        .then(data => setClientProduct(data))
        .catch(console.error)
    }
  }, [mark])
  return clientProduct ? (
    <Link
      to={`${
        clientProduct.store
          ? `/store/${clientProduct.link}`
          : `/${clientProduct.link}`
      }`}
      className="underline"
    >
      {children}
    </Link>
  ) : (
    <span className="underline">{children}</span>
  )
}

export default LinkObject
