import React from "react"
import BlockContent from "@sanity/block-content-to-react"
import ExternalLink from "./ExternalLink"
// import InternalLink from "./InternalLink"
import LinkObject from "./LinkObject"
import { richText } from "./richtext.module.scss"

const RichText = ({ blocks, className }) => {
  return (
    <BlockContent
      blocks={blocks}
      className={`${className} ${richText}`}
      serializers={{
        marks: { link: ExternalLink, linkObject: LinkObject },
      }}
    />
  )
}

export default RichText
