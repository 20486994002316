import React from "react"

const Logo = () => {
  return (
    <svg
      className="block w-auto h-full fill-current md:w-full md:h-auto"
      viewBox="0 0 433 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.47998 29.2V46.47H0V2.25H16.4C24.88 2.25 30.5 7.99003 30.5 15.72C30.5 23.45 24.88 29.2 16.4 29.2H7.47998ZM15.28 22.59C20.02 22.59 22.89 19.97 22.89 15.79C22.89 11.61 20.02 8.87 15.28 8.87H7.47998V22.59H15.28Z" />
      <path d="M60.7002 37.8C59.0802 43.1 54.2702 47.4 46.9802 47.4C38.7502 47.4 31.4502 41.4 31.4502 31.12C31.4502 21.52 38.5602 15.12 46.2302 15.12C55.5802 15.12 61.0702 21.29 61.0702 30.9C61.0958 31.6715 61.0321 32.4432 60.8802 33.2H38.6802C38.7353 35.3494 39.6395 37.3894 41.195 38.8737C42.7505 40.358 44.8307 41.1657 46.9802 41.12C51.3402 41.12 53.5902 38.82 54.5802 35.82L60.7002 37.8ZM53.7702 27.94C53.6502 24.39 51.2802 21.21 46.2902 21.21C44.4277 21.1713 42.621 21.8471 41.2411 23.0987C39.8613 24.3503 39.0129 26.0826 38.8702 27.94H53.7702Z" />
      <path d="M83.6001 23.27C82.8163 23.1445 82.0239 23.081 81.2301 23.08C75.6201 23.08 73.0601 26.3199 73.0601 31.9899V46.47H65.8301V16.09H72.8801V20.96C74.3101 17.65 77.6801 15.72 81.6701 15.72C82.3176 15.7216 82.9635 15.7818 83.6001 15.9V23.27Z" />
      <path d="M93.35 36.49C93.6 39.23 95.66 41.66 99.52 41.66C102.52 41.66 104.07 40.04 104.07 38.17C104.07 36.55 102.95 35.3 100.52 34.8L96.03 33.8C90.67 32.62 87.86 29.13 87.86 24.95C87.86 19.65 92.72 15.16 99.02 15.16C107.5 15.16 110.25 20.64 110.75 23.64L104.64 25.39C104.472 24.052 103.793 22.8306 102.746 21.981C101.699 21.1313 100.364 20.7189 99.02 20.83C96.46 20.83 94.66 22.45 94.66 24.32C94.66 25.95 95.84 27.13 97.66 27.51L102.28 28.44C108.02 29.69 111.01 33.18 111.01 37.67C111.01 42.16 107.27 47.4 99.53 47.4C90.74 47.4 87.37 41.66 87 38.23L93.35 36.49Z" />
      <path d="M146.43 31.2499C146.43 40.5999 139.76 47.4 130.66 47.4C121.56 47.4 114.94 40.5999 114.94 31.2499C114.94 21.8999 121.61 15.2 130.66 15.2C139.71 15.2 146.43 22 146.43 31.29V31.2499ZM139.14 31.29C139.14 24.99 135.14 21.7499 130.66 21.7499C126.18 21.7499 122.17 24.99 122.17 31.29C122.17 37.59 126.23 40.95 130.66 40.95C135.09 40.95 139.14 37.65 139.14 31.29Z" />
      <path d="M158.16 46.4701H150.93V16.0901H157.98V20.2C158.901 18.647 160.222 17.37 161.805 16.5027C163.388 15.6353 165.176 15.2096 166.98 15.2701C174.4 15.2701 177.98 20.6301 177.98 27.2701V46.4801H170.74V28.4801C170.74 24.7401 169.06 21.75 164.5 21.75C160.39 21.75 158.2 24.93 158.2 28.92L158.16 46.4701Z" />
      <path d="M191.81 29.06L199.61 27.88C201.35 27.63 201.85 26.76 201.85 25.7C201.85 23.14 200.11 21.08 196.11 21.08C195.34 21.0285 194.567 21.13 193.836 21.3785C193.106 21.627 192.431 22.0176 191.852 22.528C191.273 23.0383 190.801 23.6582 190.462 24.3519C190.124 25.0457 189.926 25.7995 189.88 26.57L183.27 25.07C183.83 19.84 188.57 15.16 196.05 15.16C205.41 15.16 208.96 20.46 208.96 26.51V41.6C208.938 43.2316 209.065 44.8617 209.34 46.47H202.6C202.351 45.1958 202.247 43.8976 202.29 42.6C201.282 44.1448 199.888 45.3999 198.247 46.2416C196.605 47.0834 194.773 47.4826 192.93 47.4C186.57 47.4 182.64 43.04 182.64 38.23C182.64 32.81 186.64 29.81 191.81 29.06V29.06ZM201.81 33.87V32.49L193.89 33.68C191.65 34.05 189.89 35.3 189.89 37.8C189.89 39.8 191.45 41.72 194.32 41.72C198.37 41.72 201.86 39.79 201.86 33.87" />
      <path d="M221.97 1.31006H214.74V46.4601H221.97V1.31006Z" />
      <path d="M247.44 2.25V46.47H274.76V39.48H254.93V27.75H272.89V20.96H254.93V9.23H274.76V2.25H247.44Z" />
      <path d="M310.82 12.1V16.1H317.82V22.4H310.82V46.48H303.53V22.3899H290.87V46.47H283.57V22.3899H278.27V16.09H283.57V11.91C283.57 5.11996 287.94 0.999926 294.17 0.999926C295.468 0.965122 296.76 1.17546 297.98 1.61992V7.79991C297.127 7.56507 296.244 7.4573 295.36 7.47997C293.49 7.47997 290.87 8.35996 290.87 12.1V16.1H303.53V11.9199C303.53 5.12991 307.89 1.00994 314.13 1.00994C315.425 0.973737 316.714 1.18414 317.93 1.62993V7.80992C317.077 7.57363 316.194 7.46577 315.31 7.48992C313.44 7.48992 310.82 8.36991 310.82 12.1099" />
      <path d="M349.33 37.7999C347.71 43.0999 342.9 47.3999 335.61 47.3999C327.38 47.3999 320.08 41.3999 320.08 31.1199C320.08 21.5199 327.19 15.1199 334.86 15.1199C344.21 15.1199 349.7 21.2899 349.7 30.8999C349.726 31.6713 349.662 32.4431 349.51 33.1999H327.31C327.365 35.3492 328.269 37.3893 329.825 38.8736C331.38 40.3579 333.46 41.1655 335.61 41.1199C339.97 41.1199 342.22 38.8199 343.21 35.8199L349.33 37.7999ZM342.4 27.9399C342.28 24.3899 339.91 21.2099 334.92 21.2099C333.058 21.1712 331.251 21.847 329.871 23.0986C328.491 24.3501 327.643 26.0824 327.5 27.9399H342.4Z" />
      <path d="M360.88 31.2499C360.88 37.3599 364.81 40.79 369.36 40.79C371.073 40.8393 372.753 40.3137 374.132 39.2969C375.512 38.2801 376.511 36.8307 376.97 35.1799L383.33 37.48C382.419 40.4468 380.551 43.0287 378.019 44.8231C375.486 46.6175 372.431 47.5236 369.33 47.4C360.42 47.4 353.62 40.4799 353.62 31.2499C353.62 22.0199 360.45 15.2 369.16 15.2C377.33 15.2 381.82 20.31 383.06 25.2L376.58 27.5699C376.232 25.9013 375.299 24.4119 373.949 23.3704C372.6 22.329 370.923 21.8039 369.22 21.89C364.79 21.89 360.86 25.1999 360.86 31.3099" />
      <path d="M398.63 16.09H404.93V22.52H398.63V36.6099C398.63 39.2899 399.82 40.41 402.5 40.41C403.335 40.3955 404.168 40.3152 404.99 40.1699V46.1699C403.574 46.6932 402.068 46.9278 400.56 46.8599C394.95 46.8599 391.46 43.4899 391.46 37.8599V22.52H385.85V16.09H387.41C390.65 16.09 392.08 14.03 392.08 11.35V6.79993H398.63V16.09Z" />
      <path d="M414.61 36.4899C414.86 39.2299 416.92 41.6599 420.78 41.6599C423.78 41.6599 425.34 40.0399 425.34 38.1699C425.34 36.5499 424.21 35.2999 421.78 34.7999L417.29 33.7999C411.93 32.6199 409.12 29.1299 409.12 24.9499C409.12 19.6499 413.98 15.1599 420.28 15.1599C428.77 15.1599 431.51 20.6399 432.01 23.6399L425.9 25.3899C425.65 23.6399 424.34 20.8299 420.28 20.8299C417.73 20.8299 415.92 22.4499 415.92 24.3199C415.92 25.9499 417.1 27.1299 418.92 27.5099L423.54 28.4399C429.28 29.6899 432.27 33.1799 432.27 37.6699C432.27 42.1599 428.53 47.3999 420.8 47.3999C412 47.3999 408.63 41.6599 408.26 38.2299L414.61 36.4899Z" />
      <path d="M7.47998 29.2V46.47H0V2.25H16.4C24.88 2.25 30.5 7.99003 30.5 15.72C30.5 23.45 24.88 29.2 16.4 29.2H7.47998ZM15.28 22.59C20.02 22.59 22.89 19.97 22.89 15.79C22.89 11.61 20.02 8.87 15.28 8.87H7.47998V22.59H15.28Z" />
      <path d="M60.7002 37.7999C59.0802 43.0999 54.2702 47.3999 46.9802 47.3999C38.7502 47.3999 31.4502 41.3999 31.4502 31.1199C31.4502 21.5199 38.5602 15.1199 46.2302 15.1199C55.5802 15.1199 61.0702 21.2899 61.0702 30.8999C61.0958 31.6713 61.0321 32.4431 60.8802 33.1999H38.6802C38.7353 35.3492 39.6395 37.3893 41.195 38.8736C42.7505 40.3579 44.8307 41.1655 46.9802 41.1199C51.3402 41.1199 53.5902 38.8199 54.5802 35.8199L60.7002 37.7999ZM53.7702 27.9399C53.6502 24.3899 51.2802 21.2099 46.2902 21.2099C44.4277 21.1712 42.621 21.847 41.2411 23.0986C39.8613 24.3501 39.0129 26.0824 38.8702 27.9399H53.7702Z" />
      <path d="M83.6001 23.27C82.8163 23.1445 82.0239 23.081 81.2301 23.08C75.6201 23.08 73.0601 26.3199 73.0601 31.9899V46.47H65.8301V16.09H72.8801V20.96C74.3101 17.65 77.6801 15.72 81.6701 15.72C82.3176 15.7216 82.9635 15.7818 83.6001 15.9V23.27Z" />
      <path d="M93.35 36.4899C93.6 39.2299 95.66 41.6599 99.52 41.6599C102.52 41.6599 104.07 40.0399 104.07 38.1699C104.07 36.5499 102.95 35.2999 100.52 34.7999L96.03 33.7999C90.67 32.6199 87.86 29.1299 87.86 24.9499C87.86 19.6499 92.72 15.1599 99.02 15.1599C107.5 15.1599 110.25 20.6399 110.75 23.6399L104.64 25.3899C104.472 24.0519 103.793 22.8305 102.746 21.9808C101.699 21.1311 100.364 20.7188 99.02 20.8299C96.46 20.8299 94.66 22.4499 94.66 24.3199C94.66 25.9499 95.84 27.1299 97.66 27.5099L102.28 28.4399C108.02 29.6899 111.01 33.1799 111.01 37.6699C111.01 42.1599 107.27 47.3999 99.53 47.3999C90.74 47.3999 87.37 41.6599 87 38.2299L93.35 36.4899Z" />
      <path d="M146.43 31.2499C146.43 40.5999 139.76 47.4 130.66 47.4C121.56 47.4 114.94 40.5999 114.94 31.2499C114.94 21.8999 121.61 15.2 130.66 15.2C139.71 15.2 146.43 22 146.43 31.29V31.2499ZM139.14 31.29C139.14 24.99 135.14 21.7499 130.66 21.7499C126.18 21.7499 122.17 24.99 122.17 31.29C122.17 37.59 126.23 40.95 130.66 40.95C135.09 40.95 139.14 37.65 139.14 31.29Z" />
      <path d="M158.16 46.4699H150.93V16.0899H157.98V20.1999C158.901 18.6469 160.222 17.3699 161.805 16.5025C163.388 15.6352 165.176 15.2095 166.98 15.2699C174.4 15.2699 177.98 20.6299 177.98 27.2699V46.48H170.74V28.48C170.74 24.74 169.06 21.7499 164.5 21.7499C160.39 21.7499 158.2 24.9299 158.2 28.9199L158.16 46.4699Z" />
      <path d="M191.81 29.0599L199.61 27.8799C201.35 27.6299 201.85 26.7599 201.85 25.6999C201.85 23.1399 200.11 21.0799 196.11 21.0799C195.34 21.0284 194.567 21.1298 193.836 21.3784C193.106 21.6269 192.431 22.0175 191.852 22.5278C191.273 23.0382 190.801 23.6581 190.462 24.3518C190.124 25.0455 189.926 25.7994 189.88 26.5699L183.27 25.0699C183.83 19.8399 188.57 15.1599 196.05 15.1599C205.41 15.1599 208.96 20.4599 208.96 26.5099V41.5999C208.938 43.2315 209.065 44.8616 209.34 46.4699H202.6C202.351 45.1957 202.247 43.8975 202.29 42.5999C201.282 44.1446 199.888 45.3998 198.247 46.2415C196.605 47.0833 194.773 47.4825 192.93 47.3999C186.57 47.3999 182.64 43.0399 182.64 38.2299C182.64 32.8099 186.64 29.8099 191.81 29.0599V29.0599ZM201.81 33.8699V32.4899L193.89 33.6799C191.65 34.0499 189.89 35.2999 189.89 37.7999C189.89 39.7999 191.45 41.7199 194.32 41.7199C198.37 41.7199 201.86 39.7899 201.86 33.8699" />
      <path d="M247.44 2.25V46.47H274.76V39.48H254.93V27.75H272.89V20.96H254.93V9.23H274.76V2.25H247.44Z" />
      <path d="M310.82 12.1V16.1H317.82V22.4H310.82V46.48H303.53V22.3899H290.87V46.47H283.57V22.3899H278.27V16.09H283.57V11.91C283.57 5.11996 287.94 0.999926 294.17 0.999926C295.468 0.965122 296.76 1.17546 297.98 1.61992V7.79991C297.127 7.56507 296.244 7.4573 295.36 7.47997C293.49 7.47997 290.87 8.35996 290.87 12.1V16.1H303.53V11.9199C303.53 5.12991 307.89 1.00994 314.13 1.00994C315.425 0.973737 316.714 1.18414 317.93 1.62993V7.80992C317.077 7.57363 316.194 7.46577 315.31 7.48992C313.44 7.48992 310.82 8.36991 310.82 12.1099" />
      <path d="M349.33 37.7999C347.71 43.0999 342.9 47.3999 335.61 47.3999C327.38 47.3999 320.08 41.3999 320.08 31.1199C320.08 21.5199 327.19 15.1199 334.86 15.1199C344.21 15.1199 349.7 21.2899 349.7 30.8999C349.726 31.6713 349.662 32.4431 349.51 33.1999H327.31C327.365 35.3492 328.269 37.3893 329.825 38.8736C331.38 40.3579 333.46 41.1655 335.61 41.1199C339.97 41.1199 342.22 38.8199 343.21 35.8199L349.33 37.7999ZM342.4 27.9399C342.28 24.3899 339.91 21.2099 334.92 21.2099C333.058 21.1712 331.251 21.847 329.871 23.0986C328.491 24.3501 327.643 26.0824 327.5 27.9399H342.4Z" />
      <path d="M360.88 31.2499C360.88 37.3599 364.81 40.79 369.36 40.79C371.073 40.8393 372.753 40.3137 374.132 39.2969C375.512 38.2801 376.511 36.8307 376.97 35.1799L383.33 37.48C382.419 40.4468 380.551 43.0287 378.019 44.8231C375.486 46.6175 372.431 47.5236 369.33 47.4C360.42 47.4 353.62 40.4799 353.62 31.2499C353.62 22.0199 360.45 15.2 369.16 15.2C377.33 15.2 381.82 20.31 383.06 25.2L376.58 27.5699C376.232 25.9013 375.299 24.4119 373.949 23.3704C372.6 22.329 370.923 21.8039 369.22 21.89C364.79 21.89 360.86 25.1999 360.86 31.3099" />
      <path d="M398.63 16.09H404.93V22.52H398.63V36.6099C398.63 39.2899 399.82 40.41 402.5 40.41C403.335 40.3955 404.168 40.3152 404.99 40.1699V46.1699C403.574 46.6932 402.068 46.9278 400.56 46.8599C394.95 46.8599 391.46 43.4899 391.46 37.8599V22.52H385.85V16.09H387.41C390.65 16.09 392.08 14.03 392.08 11.35V6.79993H398.63V16.09Z" />
      <path d="M414.61 36.4899C414.86 39.2299 416.92 41.6599 420.78 41.6599C423.78 41.6599 425.34 40.0399 425.34 38.1699C425.34 36.5499 424.21 35.2999 421.78 34.7999L417.29 33.7999C411.93 32.6199 409.12 29.1299 409.12 24.9499C409.12 19.6499 413.98 15.1599 420.28 15.1599C428.77 15.1599 431.51 20.6399 432.01 23.6399L425.9 25.3899C425.65 23.6399 424.34 20.8299 420.28 20.8299C417.73 20.8299 415.92 22.4499 415.92 24.3199C415.92 25.9499 417.1 27.1299 418.92 27.5099L423.54 28.4399C429.28 29.6899 432.27 33.1799 432.27 37.6699C432.27 42.1599 428.53 47.3999 420.8 47.3999C412 47.3999 408.63 41.6599 408.26 38.2299L414.61 36.4899Z" />
    </svg>
  )
}

export default Logo
