import React from "react"
import { graphql } from "gatsby"

export function ButtonAnchor({ title, to, className }) {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={to}
      className={`flex items-center line-link space-x-4 leading-none transition duration-300 h-45px md:h-30px px-15px md:px-20px  hover:text-black group p-2.5 ${className} `}
    >
      <span>{title}</span>
    </a>
  )
}

// slug: gatsbyPath(filePath: "/{SanityPage.slug__current}")
export const query = graphql`
  fragment PageInfo on SanityPage {
    _id
    title
    sections {
      sections {
        ... on SanityGrid {
          _key
          _type
          title
          columns
          cells {
            ... on SanityCell {
              _key
              _type
              rowspan
              colspan
              content {
                children {
                  text
                  marks
                  _type
                  _key
                }
                _type
                _key
              }
            }
            ... on SanityImageCell {
              _key
              _type
              colspan
              rowspan
              image {
                asset {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  }
`
