import React from "react"

const LinkBlock = ({ title, onClick }) => {
  return (
    <button
      className="block w-full leading-none text-center text-white bg-black fixed-btn"
      onClick={() => onClick()}
    >
      {title}
    </button>
  )
}

export default LinkBlock
