import React from "react"

const ExternalLink = ({ mark, children }) => {
  return (
    <a
      className="transition-colors duration-300 underline hover:text-black"
      href={mark.href}
      target="_blank"
      rel="noreferrer"
    >
      {children}
      <slot />
    </a>
  )
}

export default ExternalLink
