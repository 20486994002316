import * as React from "react"
import { StoreContext } from "../../context/store-context"
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { getShopifyImage } from "gatsby-source-shopify"
import ButtonLine from "../button/ButtonLine"
import sanityClient from "../../utils/client"
import { Buffer } from "buffer"
import { formatPrice } from "../../utils/format-price"
import imageUrlBuilder from "@sanity/image-url"

const builder = imageUrlBuilder(sanityClient)

function urlFor(source) {
  return builder.image(source)
}
export function LineItem({ item }) {
  const data = useStaticQuery(graphql`
    query SettingQuery {
      settings: allSanitySettings {
        nodes {
          cardColour {
            hex
          }
        }
      }
    }
  `)
  const hexcolor = data?.settings?.nodes[0]?.cardColour?.hex
  const { removeLineItem, checkout } = React.useContext(StoreContext)
  const [hexcodeimage, setHexcodeimage] = React.useState([])
  const [proImage, setProImage] = React.useState(null)
  let buff = Buffer(item.variant.product.id, "base64")
  let text = buff.toString("ascii")
  var number = text.replace(/^\D+/g, "")

  React.useEffect(() => {
    sanityClient
      .fetch(`*[store.id==${number}][0]{hexcodeImage}`)
      .then(data => setHexcodeimage(data.hexcodeImage))
      .catch(console.error)
  }, [number])

  React.useEffect(() => {
    if (hexcodeimage?.length > 0) {
      const getColor = item.variant.selectedOptions.find(
        v => v.name === "Color"
      )
      if (getColor) {
        const getImage = hexcodeimage.find(h => h.color === getColor.value)
        if (getImage) {
          setProImage(getImage?.images && getImage?.images[0])
        }
      }
    }
  }, [hexcodeimage, item.variant.selectedOptions])

  const variantImage = {
    ...item.variant.image,
    originalSrc: item.variant.image.src,
  }
  const presentmentPrice = item.variant?.presentmentPrices?.find(
    v => v.price.currencyCode === checkout.currencyCode
  )
  const price = formatPrice(
    checkout?.currencyCode,
    presentmentPrice ? presentmentPrice.price.amount : item.variant.price
  )

  const handleRemove = () => {
    removeLineItem(checkout?.id, item.id)
  }

  const image = React.useMemo(
    () =>
      getShopifyImage({
        image: variantImage,
        layout: "constrained",
        crop: "contain",
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variantImage.src]
  )

  return (
    <article className="grid w-full grid-cols-3 gap-3">
      <div className="flex flex-col border-b border-gray-200 py-8px">
        <figure
          className="flex items-center justify-center flex-1 aspect-w-4 aspect-h-5"
          style={{ backgroundColor: `${hexcolor}` }}
        >
          <div>
            {proImage ? (
              <img src={urlFor(proImage?.asset?._ref).url()} alt="" />
            ) : (
              image && (
                <GatsbyImage
                  key={variantImage.src}
                  image={image}
                  objectFit="contain"
                  alt={variantImage.altText ?? item.variant.title}
                />
              )
            )}
          </div>
        </figure>
      </div>
      <div className="flex flex-col self-stretch col-span-2 border-b border-gray-200 py-8px ">
        <header className="flex items-center justify-between">
          <span> {item.title}</span>
          <ButtonLine title="Remove" onClick={handleRemove}>
            Remove
          </ButtonLine>
        </header>
        <div className="flex-1">
          <div className="flex flex-col py-8px">
            {item.variant.selectedOptions.map((option, index) => {
              return (
                <span key={index}>
                  {option.name}: {option.value}
                </span>
              )
            })}
          </div>

          {price}
        </div>
        <footer className="pt-8px">
          <Link to={`/store/${item.variant.product.handle}`}>
            <ButtonLine title="Edit"></ButtonLine>
          </Link>
        </footer>
      </div>
    </article>
  )
}
