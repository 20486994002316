import React from "react"
import { HeaderContext } from "../../context/header-context"
import { StoreContext } from "../../context/store-context"

import LinkBlock from "../link/LinkBlock"

import { LineItem } from "../lineItem/line-item"
import { formatPrice } from "../../utils/format-price"

const Cart = ({ show }) => {
  const { checkout, loading } = React.useContext(StoreContext)
  const emptyCart = checkout.lineItems.length === 0

  const handleCheckout = () => {
    window.open(checkout.webUrl)
  }
  const { toggleCart } = React.useContext(HeaderContext)
  return (
    <div
      className={` ${show ? "z-50 md:z-10 " : "-translate-y-full-plus"
        } absolute right-0 w-full mb-px transform transition-transform duration-300 bg-gray-100 p-15px md:p-20px pt-0 md:pt-0 md:w-1/4 md:min-w-ch  max-h-cart overflow-auto`}
    >
      <header className="flex items-center w-full leading-none border-b border-gray-200 divide-y divide-gray-200 h-45px md:h-30px">
        <div className="flex items-center justify-between w-full">
          <span>Bag</span>
          <button className="line-link" onClick={() => toggleCart(false)}>
            <span>Close</span>
          </button>
        </div>
      </header>
      {emptyCart ? (
        <div className="leading-none text-center height ">
          Your cart is empty
        </div>
      ) : (
        <>
          <div className="flex items-center space-x-8 leading-none border-b border-gray-200 height">
            <span>Your cart contains:</span>
            <span className="border-b border-black pb-0.5">
              {checkout.lineItems.length} product
              {checkout.lineItems.length > 1 ? "s" : ""}{" "}
            </span>
          </div>

          {checkout.lineItems.map(item => (
            <LineItem item={item} key={item.id} />
          ))}

          <footer className="border-t border-gray-200 mt-60px ">
            <div className="flex items-center justify-between h-45px">
              <div>Subtotal</div>
              <div>
                {formatPrice(
                  checkout.subtotalPriceV2.currencyCode,
                  checkout.subtotalPriceV2.amount
                )}
              </div>
            </div>
            <LinkBlock
              title="Checkout"
              onClick={handleCheckout}
              disabled={loading}
            ></LinkBlock>
          </footer>
        </>
      )}
    </div>
  )
}

export default Cart
