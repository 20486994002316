import React, { useState, useEffect } from "react"
import Arrow from "../svg/arrow"

import { useStaticQuery, graphql } from "gatsby"
import { ButtonStrike } from "../button/ButtonStrike"
import { ButtonAnchor } from "../button/ButtonAnchor"
import Spacer from "../util/Spacer"
import Form from "../subscribe/Form"
import "./footer.css"
import { HeaderContext } from "../../context/header-context"
import { useMediaQuery } from "react-responsive"
const Footer = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 })
  const { showCart, showMenu } = React.useContext(HeaderContext)
  const [footer, setFooter] = useState(false)

  const toggleFooter = () => {
    setFooter(!footer)
  }
  useEffect(() => {
    if (footer) {
      const height = document.body.scrollHeight
      setTimeout(function () {
        window.scrollTo(0, height)
      }, 50)
    }
  }, [footer])
  const data = useStaticQuery(graphql`
    query FooterSettingQuery {
      sanitySettings {
        footer {
          mobileMenu {
            menuColumns {
              menuGroup {
                url
                title
                selectLink
                linkObject {
                  ... on SanityAboutLink {
                    id
                    _type
                    slug {
                      current
                    }
                    title
                  }
                  ... on SanityCollection {
                    id
                    _type
                    title
                    slug {
                      current
                    }
                  }
                  ... on SanityPage {
                    id
                    _type
                    slug {
                      current
                    }
                    title
                  }
                  ... on SanityProduct {
                    id
                    _type
                    store {
                      title
                      slug {
                        current
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const menuColumns = data.sanitySettings.footer.mobileMenu.menuColumns
  function sliceHalf(array) {
    const half = Math.ceil(array.length / 2)
    const firstHalf = array.slice(0, half)
    const secondHalf = array.slice(half - array.length)
    return {
      firstHalf,
      secondHalf,
    }
  }
  const getColum = array => {
    return array?.menuGroup ? array?.menuGroup : []
  }

  const slicedColumn1 = sliceHalf(getColum(menuColumns[0]))
  const slicedColumn2 = sliceHalf(getColum(menuColumns[1]))

  let arr = [
    slicedColumn1.firstHalf.length,
    slicedColumn1.secondHalf.length,
    slicedColumn2.firstHalf.length,
    slicedColumn2.secondHalf.length,
  ]

  let largestNum = arr.reduce(function (accumulatedValue, currentValue) {
    return Math.max(accumulatedValue, currentValue)
  })

  const fillArray = array => {
    var fillArr = []
    if (array.length < largestNum) {
      for (let index = 0; index < largestNum; index++) {
        if (array[index]) {
          fillArr.push(array[index])
        } else {
          fillArr.push("")
        }
      }
    } else {
      fillArr = array
    }
    return fillArr
  }

  const filterGroups = [
    fillArray(slicedColumn1.firstHalf),
    fillArray(slicedColumn1.secondHalf),
    fillArray(slicedColumn2.firstHalf),
    fillArray(slicedColumn2.secondHalf),
  ]
  const mobileGroups = [
    fillArray(getColum(menuColumns[0])),
    fillArray(getColum(menuColumns[1])),
  ]

  return (
    <footer
      className={`relative z-40 h-70px md:h-90px bg-white ${
        isTabletOrMobile && (showMenu || showCart) ? "hidden" : "md:block"
      } `}
    >
      <Form placeholder="Newsletter" />
      <button
        onClick={() => toggleFooter()}
        className="relative flex items-center justify-between w-full leading-none bg-gray-100 h-45px md:h-30px wrap"
      >
        <span className="md:absolute inset-0 flex items-center w-full md:py-3 md:space-x-4.5 md:pl-6 group">
          <span>Footer Information</span>
          <figure
            className={`-mb-px w-7.5px ml-auto md:ml-0 h-auto transition duration-500 transform ${
              footer ? "rotate-180" : ""
            }`}
          >
            <Arrow />
          </figure>
        </span>
        <span className="hidden ml-auto text-gray-300 md:block">
          &copy; {new Date().getFullYear()} Personal Effects
        </span>
      </button>
      {footer && (
        <nav
          className="grid items-start grid-cols-2 md:grid-cols-4  text-gray-300 bg-gray-100 border-t  "
          id="footer"
        >
          {!isTabletOrMobile
            ? filterGroups.map((groups, index) => {
                return (
                  <div className=" " key={index}>
                    {groups.map((filter, index) => {
                      const link =
                        filter?.linkObject?._type === "collection"
                          ? `/collection/${filter?.linkObject?.slug?.current}`
                          : filter?.linkObject?._type === "page"
                          ? `/${filter.linkObject?.slug?.current}`
                          : filter?.linkObject?._type === "product"
                          ? `/store/${filter.linkObject?.store?.slug?.current}`
                          : filter?.linkObject?._type === "aboutLink"
                          ? `${filter?.linkObject?.slug?.current}`
                          : "/"

                      return (
                        <span className="block border-b " key={index}>
                          {filter.title ? (
                            (filter.selectLink === "int" && (
                              <ButtonStrike
                                className="flex items-center space-x-4 leading-none transition duration-300 fixed-btn hover:text-black group md:order-none"
                                to={link}
                                title={filter.title}
                              >
                                {filter.title}
                              </ButtonStrike>
                            )) ||
                            (filter.selectLink === "ext" && (
                              <ButtonAnchor
                                className={`cursor-pointer`}
                                to={filter.url}
                                title={filter.title}
                              />
                            ))
                          ) : (
                            <Spacer />
                          )}
                        </span>
                      )
                    })}
                  </div>
                )
              })
            : mobileGroups.map((groups, index) => {
                return (
                  <div className=" " key={index}>
                    {groups.map((filter, index) => {
                      const link =
                        filter?.linkObject?._type === "collection"
                          ? `/collection/${filter?.linkObject?.slug?.current}`
                          : filter?.linkObject?._type === "page"
                          ? `/${filter.linkObject?.slug?.current}`
                          : filter?.linkObject?._type === "product"
                          ? `/store/${filter.linkObject?.store?.slug?.current}`
                          : filter?.linkObject?._type === "aboutLink"
                          ? `${filter?.linkObject?.slug?.current}`
                          : "/"

                      return (
                        <span className="block border-b " key={index}>
                          {filter.title ? (
                            (filter.selectLink === "int" && (
                              <ButtonStrike
                                className="flex items-center space-x-4 leading-none transition duration-300 fixed-btn hover:text-black group md:order-none"
                                to={link}
                                title={filter.title}
                              >
                                {filter.title}
                              </ButtonStrike>
                            )) ||
                            (filter.selectLink === "ext" && (
                              <ButtonAnchor
                                className={`cursor-pointer`}
                                to={filter.url}
                                title={filter.title}
                              />
                            ))
                          ) : (
                            <Spacer />
                          )}
                        </span>
                      )
                    })}
                  </div>
                )
              })}

          {/* <div className="grid w-full grid-cols-2 border-b md:grid-cols-4 md:border-b-0">
            <ButtonStrike
              className="border-b md:border-0"
              title="Instagram"
              to="/store"
            ></ButtonStrike>
            <ButtonStrike
              className="border-b md:border-0"
              title="Our Makers"
              to="/store"
            ></ButtonStrike>
            <ButtonStrike title="Stockists" to="/store"></ButtonStrike>
          </div>
          <div className="grid w-full grid-cols-2 md:grid-cols-4">
            <ButtonStrike title="Charity" to="/store"></ButtonStrike>
          </div> */}
        </nav>
      )}
    </footer>
  )
}

export default Footer
