import React from "react"
import "./buttonline.scss"
const ButtonLine = ({ title, onClick, className, children }) => {
  const [btnClass, setBtnClass] = React.useState("default")

  const over = () => {
    setBtnClass("over")
  }
  const out = () => {
    setBtnClass("out")
    setTimeout(function () {
      setBtnClass("default")
    }, 300)
  }
  React.useEffect(() => {
    return () => {
      setBtnClass("default")
    }
  }, [])

  return (
    <button
      className={`self-center leading-none text-black line-link ${btnClass} ${className}`}
      onClick={onClick}
      onMouseOver={() => over()}
      onFocus={() => over()}
      onMouseLeave={() => out()}
    >
      <span>
        {title}
        {children?.type === "div" && children}
      </span>
    </button>
  )
}

export default ButtonLine
