import React from "react"

const Arrow = () => {
  return (
    <svg
      viewBox="0 0 6 8"
      className="block -mb-px fill-current"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.71 0V6.22L0.41 3.92L0 4.33L3 7.33L6 4.33L5.59 3.92L3.29 6.22V0H2.71Z" />
    </svg>
  )
}

export default Arrow
