import React from "react"

import { HeaderContext } from "../../context/header-context"
import { StoreContext } from "../../context/store-context"
import { Link } from "gatsby"
// import Strike from "../link/Strike"

import Menu from "./Menu"
import MobileMenu from "./MobileMenu"
import UserMenu from "./UserMenu"
import Cart from "../cart/Cart"
import Currency from "../currency/Currency"
import "./Nav.css"
import { useMediaQuery } from "react-responsive"
import { useLocation, globalHistory } from "@reach/router"
const Nav = () => {
  const {
    showCart,
    showCurrency,
    toggleCart,
    toggleCurrency,
    toggleMenu,
    showMenu,
  } = React.useContext(HeaderContext)

  const toggleModals = () => {
    toggleMenu(!showMenu)
    toggleCart(false)
    toggleCurrency(false)
  }

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 })

  const { checkout } = React.useContext(StoreContext)

  const items = checkout ? checkout.lineItems : []

  const quantity = items.reduce((total, item) => {
    return total + item.quantity
  }, 0)
  const location = useLocation()
  const matchLocation =
    location.pathname.includes("makers") || location.pathname === "/store/"
  React.useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") toggleMenu(false)
    })
  }, [toggleMenu])
  return (
    <div
      className={`${
        isTabletOrMobile && (showMenu || showCart)
          ? "fixed md:sticky mt-45px md:mt-0 top-0 left-0 w-full"
          : "sticky"
      }  top-0 z-30`}
    >
      <div
        className="group"
        // onMouseOver={() => toggleModals()}
        // aria-hidden="true"
        // onFocus={() => toggleModals()}
      >
        <div
          className={`relative top-0 z-40 flex items-center justify-start space-x-6 leading-none bg-white border-b ${
            matchLocation && !showMenu && !showCart
              ? "border-transparent"
              : "border-gray-200"
          } h-45px md:h-30px group global-header wrap`}
        >
          <button
            className="flex items-center leading-none transition duration-300 line-link hover:text-black group"
            onClick={() => {
              toggleModals()
            }}
          >
            <span>{showMenu ? "Close" : "Menu"}</span>
          </button>
          <Link
            to="/store"
            className={`hidden md:flex ${
              location.pathname === "/store" ? "selected" : ""
            } line-link`}
          >
            <span>Shop Latest</span>
          </Link>
        </div>
        <Menu show={showMenu} />
        <MobileMenu show={showMenu} />
      </div>
      <UserMenu quantity={quantity}></UserMenu>

      <Cart show={showCart} />

      <Currency show={showCurrency} />
    </div>
  )
}

export default Nav
