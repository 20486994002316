import React from "react"
import { HeaderContext } from "../../context/header-context"
import { StoreContext } from "../../context/store-context"
import ButtonLine from "../button/ButtonLine"

import getSymbolFromCurrency from "currency-symbol-map"
const UserMenu = ({ quantity }) => {
  const {
    showCart,
    showCurrency,
    toggleCart,
    toggleCurrency,
    toggleMenu,
  } = React.useContext(HeaderContext)

  const { currencyCode } = React.useContext(StoreContext)

  const triggerCurrency = () => {
    toggleCurrency(!showCurrency)
    toggleCart(false)
    toggleMenu(false)
  }

  const triggerCart = () => {
    toggleCurrency(false)
    toggleCart(!showCart)
    toggleMenu(false)
  }

  return (
    <nav className="absolute top-0 right-0 z-40 flex items-center justify-end w-1/4 h-full pointer-events-none pr-15px md:pr-20px space-x-15px min-w-ch">
      <ButtonLine
        title={getSymbolFromCurrency(currencyCode)}
        onClick={() => {
          triggerCurrency()
        }}
        className={`hidden pointer-events-auto md:block ${
          showCurrency ? "active" : ""
        }`}
      ></ButtonLine>
      <ButtonLine
        title={`Bag `}
        onClick={() => {
          triggerCart()
        }}
        className={`pointer-events-auto ${showCart ? "active" : ""}`}
      >
        <div className="inline underline md:no-underline">{quantity}</div>
      </ButtonLine>
    </nav>
  )
}

export default UserMenu
