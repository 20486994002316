import React, { useState, useEffect } from "react"

import { ButtonStrike } from "../button/ButtonStrike"
import { ButtonAnchor } from "../button/ButtonAnchor"
import { useStaticQuery, graphql } from "gatsby"
import sanityClient from "../../utils/client"
import RichText from "../text/RichText"
import Form from "../subscribe/Form"

const MobileMenu = ({ show }) => {
  const [info, setInfo] = useState(null)

  useEffect(() => {
    sanityClient
      .fetch(`*[_id=="settings"][0]{'info':header.infoText}`)
      .then(data => setInfo(data))
      .catch(console.error)
  }, [])

  const data = useStaticQuery(graphql`
    query MobileSettingQuery {
      sanitySettings {
        header {
          mobileMenu {
            menuColumns {
              menuGroup {
                url
                title
                selectLink
                linkObject {
                  ... on SanityAboutLink {
                    id
                    _type
                    slug {
                      current
                    }
                    title
                  }
                  ... on SanityCollection {
                    id
                    _type
                    title
                    slug {
                      current
                    }
                  }
                  ... on SanityPage {
                    id
                    _type
                    slug {
                      current
                    }
                    title
                  }
                  ... on SanityProduct {
                    id
                    _type
                    store {
                      title
                      slug {
                        current
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const menuColumns = data.sanitySettings.header.mobileMenu.menuColumns

  return (
    <nav
      className={`${
        show ? "" : "-translate-y-full"
      } fixed pt-90px top-0 md:hidden flex flex-col w-full transition-transform duration-300 transform bg-white h-full md:h-auto`}
    >
      <div className="border-b border-gray-200 divide-y divide-gray-200">
        {menuColumns[0] !== undefined &&
          menuColumns[0].menuGroup.map((filter, index) => {
            const link =
              filter?.linkObject?._type === "collection"
                ? `/collection/${filter?.linkObject?.slug?.current}`
                : filter?.linkObject?._type === "page"
                ? `/${filter.linkObject?.slug?.current}`
                : filter?.linkObject?._type === "product"
                ? `/store/${filter.linkObject?.store?.slug?.current}`
                : filter?.linkObject?._type === "aboutLink"
                ? `${filter?.linkObject?.slug?.current}`
                : "/"
            return filter.selectLink === "int" ? (
              <ButtonStrike
                key={index}
                className="flex items-center space-x-4 leading-none transition duration-300 fixed-btn hover:text-black group md:order-none"
                to={link}
                title={filter.title}
              ></ButtonStrike>
            ) : (
              filter.selectLink === "ext" && (
                <ButtonAnchor
                  key={index}
                  className={`cursor-pointer`}
                  to={filter.url}
                  title={filter.title}
                />
              )
            )
          })}
      </div>
      <div className="text-gray-300 border-t border-b border-gray-200 divide-y divide-gray-200 bg-gray-50 mt-45px">
        {menuColumns[1] !== undefined &&
          menuColumns[1].menuGroup.map((filter, index) => {
            const link =
              filter?.linkObject?._type === "collection"
                ? `/collection/${filter?.linkObject?.slug?.current}`
                : filter?.linkObject?._type === "page"
                ? `/${filter.linkObject?.slug?.current}`
                : filter?.linkObject?._type === "product"
                ? `/store/${filter.linkObject?.store?.slug?.current}`
                : filter?.linkObject?._type === "aboutLink"
                ? `${filter?.linkObject?.slug?.current}`
                : "/"
            return filter.selectLink === "int" ? (
              <ButtonStrike
                key={index}
                className="flex items-center space-x-4 leading-none transition duration-300 fixed-btn hover:text-black group md:order-none"
                to={link}
                title={filter.title}
              ></ButtonStrike>
            ) : (
              filter.selectLink === "ext" && (
                <ButtonAnchor
                  key={index}
                  className={`cursor-pointer`}
                  to={filter.url}
                  title={filter.title}
                />
              )
            )
          })}
      </div>
      <footer className="flex mt-auto text-gray-300 border-t border-gray-200 divide-x divide-gray-200 bg-gray-50">
        <ButtonAnchor
          className="w-full text-center fixed-btn"
          title="Instagram"
          to="https://www.instagram.com/personaleffects.london/"
        ></ButtonAnchor>
        <ButtonAnchor
          className="w-full text-center fixed-btn"
          title="Facebook"
          to="https://www.facebook.com/personaleffects.london/"
        ></ButtonAnchor>
      </footer>
      <div className="border-t border-gray-200">
        <Form placeholder="Newsletter" />
      </div>
      <div>
        <span className="z-10 block leading-none text-gray-300 border-t height padding">
          {info &&
            info.info.map(child => {
              child.markDefs = child.markDefs ? child.markDefs : []
              return <RichText key={child._key} blocks={child} />
            })}
        </span>
      </div>
    </nav>
  )
}

export default MobileMenu
