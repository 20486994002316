import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { ButtonStrike } from "../button/ButtonStrike"
import { ButtonAnchor } from "../button/ButtonAnchor"
import sanityClient from "../../utils/client"
import RichText from "../text/RichText"
import Spacer from "../util/Spacer"
const Menu = ({ show }) => {
  const [info, setInfo] = useState(null)

  useEffect(() => {
    sanityClient
      .fetch(`*[_id=="settings"][0]{'info':header.infoText}`)
      .then(data => setInfo(data))
      .catch(console.error)
  }, [])
  const data = useStaticQuery(graphql`
    query SettingsQuery {
      sanitySettings {
        header {
          menu {
            menuColumns {
              menuGroup {
                url
                title
                selectLink
                linkObject {
                  ... on SanityAboutLink {
                    id
                    _type
                    slug {
                      current
                    }
                    title
                  }
                  ... on SanityCollection {
                    id
                    _type
                    title
                    slug {
                      current
                    }
                  }
                  ... on SanityPage {
                    id
                    _type
                    slug {
                      current
                    }
                    title
                  }
                  ... on SanityProduct {
                    id
                    _type
                    store {
                      title
                      slug {
                        current
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const menuColumns = data.sanitySettings.header.menu.menuColumns
  const getColum = array => {
    return array?.menuGroup ? array?.menuGroup?.length : 0
  }
  let arr = [
    getColum(menuColumns[0]),
    getColum(menuColumns[1]),
    getColum(menuColumns[2]),
    getColum(menuColumns[3]),
  ]

  let largestNum = arr.reduce(function (accumulatedValue, currentValue) {
    return Math.max(accumulatedValue, currentValue)
  })

  const fillArray = column => {
    var fillArr = []
    const array = column?.menuGroup !== undefined ? column?.menuGroup : []
    if (array.length < largestNum) {
      for (let index = 0; index < largestNum; index++) {
        if (array[index]) {
          fillArr.push(array[index])
        } else {
          fillArr.push("")
        }
      }
    } else {
      fillArr = array
    }
    return fillArr
  }

  const filterGroups = [
    fillArray(menuColumns[0]),
    fillArray(menuColumns[1]),
    fillArray(menuColumns[2]),
    fillArray(menuColumns[3]),
    [...Array(largestNum).keys()],
    [...Array(largestNum).keys()],
    [...Array(largestNum).keys()],
    [...Array(largestNum).keys()],
  ]
  return (
    <div
      className={`${
        show ? "" : "-translate-y-full"
      } absolute w-full -mt-px transition-transform duration-300 transform bg-white`}
    >
      <nav className="relative grid items-start grid-cols-1 text-gray-300 border-b border-gray-100 divide-gray-100 md:grid md:divide-y-0 md:grid-cols-8 ">
        {filterGroups.map((groups, index) => {
          return (
            <div
              className="border-gray-100 divide-y divide-gray-100 "
              key={index}
            >
              {groups.map((filter, index) => {
                const link =
                  filter?.linkObject?._type === "collection"
                    ? `/collection/${filter?.linkObject?.slug?.current}`
                    : filter?.linkObject?._type === "page"
                    ? `/${filter.linkObject?.slug?.current}`
                    : filter?.linkObject?._type === "product"
                    ? `/store/${filter.linkObject?.store?.slug?.current}`
                    : filter?.linkObject?._type === "aboutLink"
                    ? `${filter?.linkObject?.slug?.current}`
                    : "/"

                return (
                  <span className="block" key={index}>
                    {filter.title ? (
                      (filter.selectLink === "int" && (
                        <ButtonStrike
                          className="flex items-center space-x-4 leading-none transition duration-300 fixed-btn hover:text-black group md:order-none"
                          to={link}
                          title={filter.title}
                        >
                          {filter.title}
                        </ButtonStrike>
                      )) ||
                      (filter.selectLink === "ext" && (
                        <ButtonAnchor
                          className={`cursor-pointer`}
                          to={filter.url}
                          title={filter.title}
                        />
                      ))
                    ) : (
                      <Spacer />
                    )}
                  </span>
                )
              })}
            </div>
          )
        })}

        <div className="absolute bottom-0 right-0 flex items-center h-30px mr-20px">
          {info &&
            info.info.map(child => {
              child.markDefs = child.markDefs ? child.markDefs : []
              return <RichText key={child._key} blocks={child} />
            })}
        </div>

        {/* <div className="order-3 border-b border-gray-100 divide-y divide-gray-100 md:border-none md:order-none">
          <ButtonStrike
            className="flex items-center space-x-4 leading-none transition duration-300 fixed-btn hover:text-black group md:order-none"
            title="Makers"
            to="/makers"
          ></ButtonStrike>
          <ButtonStrike
            title="About"
            to="/about"
            className="fixed-btn"
          ></ButtonStrike>
          <ButtonStrike
            title="Stockists"
            className="fixed-btn"
            to="/"
          ></ButtonStrike>
          <ButtonStrike
            title="Contact"
            className="fixed-btn"
            to="/"
          ></ButtonStrike>
        </div>
        <div className="order-first border-b divide-y divide-gray-100 md:order-none border-gray-50 md:border-none">
          <ButtonStrike
            className="fixed-btn"
            title="Store"
            to="/store"
          ></ButtonStrike>
          <ButtonStrike
            className="fixed-btn"
            title="New Arrivals"
            to="/store"
          ></ButtonStrike>
          <ButtonStrike
            className="fixed-btn"
            title="Collaborations"
            to="/"
          ></ButtonStrike>
          <Spacer></Spacer>
        </div>
        <div className="order-2 border-b divide-y divide-gray-100 md:order-none border-gray-50 md:border-none">
          <ButtonStrike
            className="fixed-btn"
            title="Archive Store"
            to="/"
          ></ButtonStrike>
          <Spacer></Spacer>
          <Spacer></Spacer>
          <Spacer></Spacer>
        </div>
        <div className="order-4 divide-y divide-gray-100 md:order-none">
          <Strike
            title="Instagram"
            className={"bg-gray-50 md:bg-transparent fixed-btn"}
            to="https://instagram.com/personaleffects"
          ></Strike>
          <Strike
            title="Facebook"
            className={"bg-gray-50 md:bg-transparent fixed-btn"}
            to="https://facebook.com/personaleffects"
          ></Strike>
          <Strike
            title="Twitter"
            className={"bg-gray-50 md:bg-transparent fixed-btn"}
            to="https://twitter.com/personaleffects"
          ></Strike>
          <Strike
            title="Newsletter"
            className={"bg-gray-50 md:bg-transparent fixed-btn"}
            to="https://instagram.com/personaleffects"
          ></Strike>
        </div> */}
        {/* <div className="hidden divide-y divide-gray-100 md:col-span-4 md:block md:order-none">
          <Spacer></Spacer>

          <Spacer></Spacer>
          <Spacer></Spacer>
          <span className="block p-4 py-2.5 leading-none text-right z-10">
            For enquiries or fit information email us at &nbsp;
            <a
              href="mailto:info@personaleffects.london"
              className="text-gray-300 transition-colors duration-300 border-b border-current hover:text-black"
            >
              info@personaleffects.london
            </a>
          </span>
        </div> */}
      </nav>
    </div>
  )
}

export default Menu
