/**
 * Formats a currency according to the user's locale
 * @param {string} currency The ISO currency code
 * @param {number} value The amount to format
 * @returns
 */

import getSymbolFromCurrency from "currency-symbol-map"
export const formatPrice = (currencyCode, price) => {
  const symbol = getSymbolFromCurrency(currencyCode)
  const amount = new Intl.NumberFormat("en-US", {
    style: "decimal",
    // minimumFractionDigits: 2,
  }).format(price)
  const formatPrice = symbol + amount
  return formatPrice
}
