import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
// import "./util.scss"
const UtilImage = ({ image, className, objectFit }) => {
  return (
    <GatsbyImage
      image={image}
      style={{ position: "absolute" }}
      className={`${className} w-full test`}
      objectFit={objectFit}
      placeholder="none"
      alt=""
    />
  )
}

export default UtilImage
