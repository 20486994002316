import * as React from "react"
import { StoreProvider } from "./src/context/store-context"
import { HeaderProvider } from "./src/context/header-context"
import "./src/styles/global.scss"

const Layout = require("./src/components/layout/layout").default

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>
}
export const wrapRootElement = ({ element }) => (
  <HeaderProvider>
    <StoreProvider>{element}</StoreProvider>
  </HeaderProvider>
)
