// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-collection-sanity-collection-slug-current-index-jsx": () => import("./../../../src/pages/collection/{SanityCollection.slug__current}/index.jsx" /* webpackChunkName: "component---src-pages-collection-sanity-collection-slug-current-index-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-store-index-jsx": () => import("./../../../src/pages/store/index.jsx" /* webpackChunkName: "component---src-pages-store-index-jsx" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/Product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-sanity-page-js": () => import("./../../../src/templates/SanityPage.js" /* webpackChunkName: "component---src-templates-sanity-page-js" */)
}

