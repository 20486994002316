import React from "react"
import { motion, AnimatePresence } from "framer-motion"
import Form from "../subscribe/Form"
import { useStaticQuery, graphql } from "gatsby"
import UtilImage from "../util/UtilImage"

const Popup = ({ togglePopup, showPopup }) => {
  const variants = {
      hidden: {
        opacity: 0,
      },
      visible: {
        opacity: 1,
      },
    },
    data = useStaticQuery(graphql`
      query {
        allSanitySettings {
          nodes {
            newsletter {
              title
              captions
              Image {
                asset {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    `)
  return (
    <AnimatePresence exitBeforeEnter onExitComplete={() => togglePopup()}>
      {showPopup && (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-full h-full align-middle ">
          <motion.div
            className="absolute w-full h-full bg-black bg-opacity-50"
            layout="true"
            variants={variants}
            initial="hidden"
            animate="visible"
            exit={{ opacity: 0 }}
          ></motion.div>
          <motion.div
            className="w-full md:w-1/2 p-15px md:p-0"
            layout="true"
            variants={variants}
            initial="hidden"
            animate="visible"
            exit={{ opacity: 0 }}
          >
            <div className={`w-full relative bg-white`}>
              <div className="flex items-center justify-between text-white bg-black px-15px h-30px md:h-45px">
                <span>Subscribe to our newsletter</span>
                <button onClick={() => togglePopup()}>
                  <span>Close</span>
                </button>
              </div>
              <header className="relative flex items-center leading-none h-70px md:h-auto">
                <h2 className="pr-10 leading-none heading">
                  {data.allSanitySettings.nodes[0].newsletter.title}
                </h2>
              </header>
              <figure className="w-full aspect-w-3 aspect-h-4 md:aspect-w-2 md:aspect-h-1">
                <UtilImage
                  image={
                    data.allSanitySettings.nodes[0].newsletter.Image.asset
                      .gatsbyImageData
                  }
                  className="absolute top-0 left-0 w-full h-full picture-cover"
                ></UtilImage>
              </figure>
              <footer>
                <Form placeholder="Email" />
              </footer>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  )
}

export default Popup
