import React from "react"
import { Link } from "gatsby"
import Marquee from "react-fast-marquee"
import Logo from "../svg/logo"
import { HeaderContext } from "../../context/header-context"
import { useMediaQuery } from "react-responsive"

const Header = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 })
  const { showCart, showMenu } = React.useContext(HeaderContext)
  return (
    <div
      className={`
    ${
      isTabletOrMobile && (showMenu || showCart)
        ? "fixed md:relative top-0 left-0 w-full"
        : "relative"
    } 
     top-0 z-40 flex items-center bg-white border-b border-gray-200 h-45px md:h-60px`}
    >
      <div className="block w-full overflow-hidden md:h-14">
        <Marquee speed={50} gradient={false} pauseOnHover={true}>
          <div className="flex items-center">
            <Link to="/" className="block h-38px md:h-auto md:w-104 lg:w-124">
              <Logo />
              {/* <Logo /> */}
            </Link>
            <div className="text-center px-45px md:px-0 md:w-1/2-screen 2xl:w-710px">
              <span>LTD Edition · Made in LDN</span>
            </div>
            <Link to="/" className="block h-38px md:h-auto md:w-104 lg:w-124">
              {/* <Logo /> */}
              <Logo />
            </Link>
            <div className="text-center px-45px md:px-0 md:w-1/2-screen 2xl:w-710px">
              <span>LTD Edition · Made in LDN</span>
            </div>
          </div>
        </Marquee>
      </div>
    </div>
  )
}

export default Header
