import React from "react"

export function ButtonCurrency({ title, className, onClick, active }) {
  return (
    <button
      onClick={onClick}
      className={`w-full flex items-center h-45px md:h-30px space-x-20px leading-none transition duration-300 capitalize hover:text-black group ${className} 
      ${active ? "text-black" : "text-gray-300"}`}
    >
      <svg
        className={`w-9px h-9px stroke-current ${active ? "fill-current" : ""}`}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z" />
      </svg>

      <span>{title}</span>
    </button>
  )
}
