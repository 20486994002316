import * as React from "react"

import { useCookies } from "react-cookie"
const defaultValues = {
  showCart: false,
  showCurrency: false,
  showFilters: false,
  showMenu: false,
}

export const HeaderContext = React.createContext(defaultValues)

export const HeaderProvider = ({ children }) => {
  const [cookie, setCookie] = useCookies(["loadOnce"])
  const [showCart, setCart] = React.useState(false)
  const [showCurrency, setCurrency] = React.useState(false)
  const [showFilters, setFilters] = React.useState(false)
  const [showMenu, setMenu] = React.useState(false)

  const [activeAccordion, setActiveAccordion] = React.useState(false)

  const [showPopup, setPopup] = React.useState(false)

  React.useEffect(() => {
    if (!cookie.loadOnce) {
      var timer = setTimeout(() => {
        setPopup(true)
      }, 2000)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [showPopup, cookie])
  const togglePopup = () => {
    setPopup(false)

    setCookie("loadOnce", true, { secure: true })
  }
  const toggleCart = value => {
    setCart(value)
  }
  const toggleCurrency = value => {
    setCurrency(value)
  }
  const toggleFilters = value => {
    setFilters(value)
  }
  const toggleMenu = menu => {
    setMenu(menu)
  }

  return (
    <HeaderContext.Provider
      value={{
        ...defaultValues,
        showCart,
        showCurrency,
        showMenu,
        showFilters,
        showPopup,
        toggleCart,
        toggleCurrency,
        toggleFilters,
        toggleMenu,
        togglePopup,
        activeAccordion,
        setActiveAccordion,
      }}
    >
      {children}
    </HeaderContext.Provider>
  )
}
