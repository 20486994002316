import React from "react"
import { HeaderContext } from "../../context/header-context"
import { StoreContext } from "../../context/store-context"
import { ButtonCurrency } from "../button/ButtonCurrency"

const Currency = ({ show }) => {
  const { toggleCurrency } = React.useContext(HeaderContext)
  const { currencyCode, updateCurrencyCheckout } = React.useContext(
    StoreContext
  )
  return (
    <div
      className={`${
        show ? "" : "-translate-y-full"
      }  absolute right-0 w-1/5 mb-px  transform transition-transform duration-300  bg-gray-100 p-15px md:p-20px pt-0 md:pt-0 min-w-ch-sm`}
    >
      <header className="leading-none text-gray-300 border-b border-gray-200 divide-y divide-gray-200 ">
        <div className="flex items-center justify-between text-black h-45px md:h-30px">
          <span>Currency</span>
          <button
            onClick={() => toggleCurrency(false)}
            className="transition duration-300 line-link"
          >
            <span>Close</span>
          </button>
        </div>
        <div className="flex items-center text-black h-30px md:h-30px">
          <span>You are currently shopping in {currencyCode}</span>
        </div>
        <ButtonCurrency
          className={` text-black`}
          title="GBP Pound Sterling"
          active={currencyCode === "GBP" ? true : false}
          onClick={() => updateCurrencyCheckout("GBP")}
        ></ButtonCurrency>
        <ButtonCurrency
          className={` text-black `}
          title="JPY Japanese Yen"
          active={currencyCode === "JPY" ? true : false}
          onClick={() => updateCurrencyCheckout("JPY")}
        ></ButtonCurrency>
        <ButtonCurrency
          className={` text-black `}
          title="CAD Canadian Dollars"
          active={currencyCode === "CAD" ? true : false}
          onClick={() => updateCurrencyCheckout("CAD")}
        ></ButtonCurrency>
        <ButtonCurrency
          className={` text-black `}
          title="DKK Kroner"
          active={currencyCode === "DKK" ? true : false}
          onClick={() => updateCurrencyCheckout("DKK")}
        ></ButtonCurrency>
        <ButtonCurrency
          className={` text-black `}
          title="EUR Euro"
          active={currencyCode === "EUR" ? true : false}
          onClick={() => updateCurrencyCheckout("EUR")}
        ></ButtonCurrency>
      </header>
    </div>
  )
}

export default Currency
