import * as React from "react"
import fetch from "isomorphic-fetch"
// import Client from "shopify-buy"
import Client from "shopify-buy/index.unoptimized.umd"
import { Buffer } from "buffer"
const client = Client.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  },
  fetch
)

const defaultValues = {
  cart: [],
  isOpen: false,
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
  client,
  checkout: {
    lineItems: [],
  },
  currencyCode: "GBP",
}

export const StoreContext = React.createContext(defaultValues)

const isBrowser = typeof window !== `undefined`
const localStorageKey = `shopify_checkout_id`
const currencyCodeKey = `currencyCode`

export const StoreProvider = ({ children }) => {
  const localCurrency = isBrowser ? localStorage.getItem(currencyCodeKey) : null

  const [checkout, setCheckout] = React.useState(defaultValues.checkout)
  const [loading, setLoading] = React.useState(false)
  const [didJustAddToCart, setDidJustAddToCart] = React.useState(false)
  const [currencyCode, setCurrencyCode] = React.useState(
    localCurrency ? localCurrency : defaultValues.currencyCode
  )

  const setCheckoutItem = checkout => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id)
    }

    setCheckout(checkout)
  }

  React.useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout = await client.checkout.fetch(
            existingCheckoutID
          )
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout)

            return
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, null)
        }
      }
      const currencyCode = defaultValues.currencyCode
      const input = {
        presentmentCurrencyCode: currencyCode,
      }
      const newCheckout = await client.checkout.create(input)
      setCheckoutItem(newCheckout)
    }

    initializeCheckout()
  }, [])

  const fetchProductById = async id => {
    let productId = "gid://shopify/Product/" + id
    let buf = Buffer.from(productId, "utf8")
    productId = buf.toString("base64")
    const clientPro = await client.product.fetch(productId)
    return clientPro
  }

  const addVariantToCart = (variantId, quantity) => {
    setLoading(true)

    const checkoutID = checkout.id

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ]
    return client.checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then(res => {
        setCheckout(res)
        setLoading(false)
        setDidJustAddToCart(true)

        setTimeout(() => setDidJustAddToCart(false), 3000)
      })
  }

  const removeLineItem = (checkoutID, lineItemID) => {
    setLoading(true)

    return client.checkout
      .removeLineItems(checkoutID, [lineItemID])
      .then(res => {
        setCheckout(res)
        setLoading(false)
      })
  }

  const updateLineItem = (checkoutID, lineItemID, quantity) => {
    setLoading(true)

    const lineItemsToUpdate = [
      { id: lineItemID, quantity: parseInt(quantity, 10) },
    ]

    return client.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then(res => {
        setCheckout(res)
        setLoading(false)
      })
  }

  const updateCurrencyCheckout = async value => {
    setCurrencyCode(value)
    const input = {
      presentmentCurrencyCode: value,
    }
    const newCheckout = await client.checkout.create(input)
    setCheckoutItem(newCheckout)
    localStorage.setItem(currencyCodeKey, value)
  }

  const addDiscountCheckout = discountCode => {
    const checkoutID = checkout.id
    client.checkout.addDiscount(checkoutID, discountCode).then(checkout => {
      // Do something with the updated checkout
      console.log(checkout)
    })
  }

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        checkout,
        loading,
        didJustAddToCart,
        currencyCode,
        updateCurrencyCheckout,
        // currencies,
        fetchProductById,
        addDiscountCheckout,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
