import React, { useState } from "react"
import { HeaderContext } from "../../context/header-context"
import { subscribe } from "klaviyo-subscribe"
const Form = ({ placeholder }) => {
  const { togglePopup } = React.useContext(HeaderContext)
  // const { addDiscountCheckout } = React.useContext(StoreContext)
  const [email, setEmail] = useState("")

  const onClickDiscount = async e => {
    const listId = "VPHgSs"
    e.preventDefault()
    await subscribe(listId, email).then(response => {
      console.log(response)
      // addDiscountCheckout("SIGNUP")
    })
    togglePopup()
  }
  return (
    <form
      action="#"
      className="flex justify-between w-full leading-none heading h-70px md:h-90px text-large group"
    >
      <input
        type="email"
        placeholder={placeholder}
        className="w-full placeholder-black md:flex-1"
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <button
        type="submit"
        className="leading-none text-gray-200 transition-colors duration-300 hover:text-black"
        onClick={e => onClickDiscount(e)}
      >
        Subscribe
      </button>
    </form>
  )
}

export default Form
